@font-face {
  font-family: Trebuchet;
  src: url(./fonts/Trebuchet\ MS\ Bold.ttf) format('truetype');
}

.App {
  text-align: center;
  justify-self: center;
}

.homeRow {
  height: 100vh;
}

.appContainer {
  margin-top: 3.5rem;
}

.welcomeImage {
  max-width: 100%;
}

.welcomeContainer {
  background-color: #d2b0aa89;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.text {
  margin: 5px;
  font-family: 'Trebuchet MS';
}

.welcomeImage {
  background-color: #b3a9c5;
}

.collapsContainer {
  justify-content: center;
  align-content: center;
}

.buttonContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  display: block;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  border-width: 1;
  border-color: #b3a9c53f;
  padding: 10px;
  border-width: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto;
  width: 90%;
}

.button:active {
  display: block;
  padding: 10px;
  border-width: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: auto;
  width: 90%;
  background-color: #b3a9c53f;
  box-shadow: -1px 2px rgb(255, 255, 255);
  color: white;
  border: 1px solid #b3a9c53f;
  outline: none;
  font-weight: 700;
  border-radius: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  transform: translateY(4px);
}

.insideCollapsable {
  display: flex;
  background-color: #b3a9c5;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 90%;
  box-shadow: -1px 2px #0000006e;
}

.input {
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.labelList {
  text-align: start;
  list-style-type: none;
  margin: 20px;
}

.labelItem {
  margin: 5px;
}

.formScreen {
  align-items: center;
  background-color: #d2b0aa89;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.loginScreen {
  align-items: center;
  background-color: #d2b0aa89;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.create {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.textLabel,
.textInput {
  display: block;
}

.textInput {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: -1px 2px #0000006e;
}

.textLabel {
  margin-top: 10px;
  margin-bottom: 10px;
}

input {
  align-content: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 80%;
  border-radius: 10px;
  box-sizing: border-box;
  border-width: 0;
}

.formButton {
  background-color: #b3a9c5;
  border: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: 'Trebuchet MS';
  border-radius: 20px;
  cursor: pointer;
  width: 30%;
}

.formButton:active {
  background-color: #b3a9c53f;
  box-shadow: -1px 2px rgb(255, 255, 255);
  color: white;
  border: 1px solid #b3a9c53f;
  outline: none;
  font-weight: 700;
  border-radius: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  transform: translateY(4px);
  width: 30%;
}


div.error {
  padding: 10px;
  color: red;
  border-width: 1px;
  border-style: solid;
  border-color: red;
  border-radius: 4px;
  margin: 20px, 0;
}

.textError {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 80%;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 0, 0, 0.46);
  box-sizing: border-box;
  box-shadow: -1px 2px #0000006e;
}

.loginButton {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  font-family: 'Trebuchet MS';
  border-radius: 20px;
  cursor: pointer;
  background-color: #b3a9c5;
  border: 0;
}

.loginButton:active {
  background-color: #b3a9c53f;
  box-shadow: -1px 2px rgb(255, 255, 255);
  color: white;
  border: 1px solid #b3a9c53f;
  outline: none;
  font-weight: 700;
  border-radius: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  transform: translateY(4px);
}

.deleteButton{
  background-color: #605e6494;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: 'Trebuchet MS';
  border-radius: 20px;
  cursor: pointer;
  width: 20%;
}

.deleteButton:active {
  background-color: #b3a9c53f;
  box-shadow: -1px 2px rgb(255, 255, 255);
  color: white;
  border: 1px solid #b3a9c53f;
  outline: none;
  font-weight: 700;
  border-radius: 30px;
  font-size: 15px;
  margin-bottom: 10px;
  transform: translateY(4px);
  width: 20%;
}

@media screen and (max-width: 767px) {
  .insideCollapsable {
    margin-left: 15px;
  }

  .welcomeImage {
    max-width: 15%;
  }

  .welcomeContainer {
    height: 100vh;
  }

  .formButton {
    width: auto;
  }
  
  .formButton:active {
    width: auto;
  }
}
